
























































import marked from "marked";
import Vue from "vue";
import axios from "axios";
let rendererMD = new marked.Renderer();
marked.setOptions({
  renderer: rendererMD,
  gfm: true,
  breaks: false,
  pedantic: false,
  sanitize: false,
  smartLists: true,
  smartypants: false,
});
rendererMD.heading = function (text, level) {
  return `<h${level} style="margin-bottom:20px;margin-top:20px">${text}</h${level}>`;
};
rendererMD.link = function (href, title, text) {
  return `<a style="color:#4183c4;text-decoration: none;" href=${href}>${text}</a>`;
};
export default Vue.extend({
  data: () => ({
    text: ``,
    papers: [] as any,
    page: 1,
    totalPage: [] as any,
    numInOnePage: 20,
    carouselPics: [
    {
        src: require("../assets/carousel/photo01.jpg"),
      },
      {
        src: require("../assets/carousel/photo15.jpg"),
      },
      {
        src: require("../assets/carousel/photo16.jpg"),
      },
      {
        src: require("../assets/carousel/photo17.jpg"),
      },
      {
        src: require("../assets/carousel/photo18.jpg"),
      },
      {
        src: require("../assets/carousel/photo12.jpg"),
      },
      {
        src: require("../assets/carousel/photo07.jpg"),
      },
      {
        src: require("../assets/carousel/photo19.jpg"),
      },
      {
        src: require("../assets/carousel/photo09.jpg"),
      },
    ],
  }),
  computed: {
    markdown: function () {
      return marked(this.text);
    },
  },
  mounted: function () {
    axios
      .post(Vue.prototype.serverAddr + "/api/get-item", {
        collection: "intro",
        need: {
          index: -2,
        },
      })
      .then((response) => {
        let tmp = (response.data as any)[0].content;
        tmp = tmp.replace(/localhost/g, Vue.prototype.serverAddr);
        this.text = tmp;
      });
    axios
        .post(Vue.prototype.serverAddr + "/api/get-item-num", {
          collection: "achievement",
          need:{
            type:0
          }    
        })
        .then((response) => {
          console.log("get response");
          this.totalPage = Math.ceil((response.data as any).num / this.numInOnePage);
        });
    this.getPaperList();
  },
  watch: {
    "page": function (val, oldVal) {
      console.log("page" + oldVal +" to " + val);
      this.getPaperList();
    },
  },
  methods:{
    getPaperList() {
      axios
        .post(Vue.prototype.serverAddr + "/api/get-item-list", {
          collection: "achievement",
          need:{
            type:0
          },
          page: this.page,
          numInOnePage: this.numInOnePage,
        })
        .then((response) => {
          console.log("get response");
          this.papers = response.data;
        });
    },
  }
});
